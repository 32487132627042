import React from 'react'
import { navigate } from 'gatsby-link'
import { injectIntl, FormattedMessage } from 'react-intl'

function encode(data) {
    return Object.keys(data)
        .map(
            (key) =>
                encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&')
}

function setActionPath(langKey) {
    let path
    if (langKey === 'en') {
        path = '/en/contact/thanks/'
    } else {
        path = '/ka/contact/thanks/'
    }
    return path
}

class HireUs extends React.Component {
    constructor(props) {
        super(props)
        this.state = { isValidated: false }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/?no-cache=1', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...this.state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch((error) => alert(error))
    }
    render() {
        const action = setActionPath(this.langKey)
        const handleSubmit = this.handleSubmit
        const handleChange = this.handleChange
        const { intl } = this.props

        return (
            <section className="section">
                <div className="container">
                    <form
                        name="contact"
                        method="post"
                        action={action}
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={handleSubmit}
                    >
                        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                        <input type="hidden" name="form-name" value="contact" />
                        <div hidden>
                            <label>
                                Don’t fill this out:{' '}
                                <input
                                    name="bot-field"
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className="field">
                            <div className="control">
                                <input
                                    className="mt-3 input is-primary has-input-style"
                                    placeholder={intl.formatMessage({
                                        id: 'contact.name',
                                    })}
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                    id="name"
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <input
                                    className="mt-3 input is-primary has-input-style"
                                    placeholder={intl.formatMessage({
                                        id: 'contact.email',
                                    })}
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    id="email"
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label
                                className="label has-text-weight-medium"
                                htmlFor="message"
                            ></label>
                            <div className="control">
                                <textarea
                                    className="mt-3 textarea is-primary has-input-style"
                                    placeholder={intl.formatMessage({
                                        id: 'contact.message',
                                    })}
                                    name="message"
                                    onChange={handleChange}
                                    id="message"
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <button
                                    className="mt-2 button has-text-weight-medium is-primary is-outlined is-medium"
                                    type="submit"
                                >
                                    <FormattedMessage id="contact.send" />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        )
    }
    langKey() {
        throw new Error('Method not implemented.')
    }
}

export default injectIntl(HireUs)
